import { Flex, HStack, Text } from '@chakra-ui/react';
import { Author } from '../common/libs/article';
import { IoBookmarkOutline, IoEyeOutline, MdHistory } from './Icons';
import CommaFormattedNumber from './CommaFormattedNumber';

function formatIsoDateString(string: string) {
  const date = new Date(string);
  return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
}

function ArticleMetrics({
  author,
  sessionCount,
  likeCount,
  updateDateIsoString,
  viewType = 'article',
}: {
  author?: Author;
  sessionCount: number;
  likeCount: number;
  updateDateIsoString: string; // ISO8601
  viewType?:
    | 'article' // スライド詳細ページで使用する時
    | 'list' // スライド一覧で使用する時
    | 'list-mobile'; // スライド一覧でモバイルレイアウトで使用する時
}) {
  const eyeIconSize = '1.1em';
  const iconSize = '1.1em';
  const iconColor = '#999999';
  const fontSize = viewType === 'article' ? 'sm' : 'xs';

  const updateDateFormatted = formatIsoDateString(updateDateIsoString);

  return (
    <Flex wrap="wrap" columnGap={3} rowGap={1}>
      {viewType === 'list-mobile' && author && (
        <HStack spacing={0.5}>
          <Text
            fontSize="sm"
            lineHeight={1.3}
            display="inline-block"
            noOfLines={1}
          >
            {`${author.lastName || ''}${author.firstName || ''}`}
          </Text>
        </HStack>
      )}
      <Flex wrap="wrap" columnGap={3}>
        <HStack spacing={0.5} wrap="nowrap">
          <IoEyeOutline color={iconColor} size={eyeIconSize} />
          <Text fontSize={fontSize}>
            <CommaFormattedNumber>{sessionCount}</CommaFormattedNumber>
          </Text>
        </HStack>

        {viewType !== 'list-mobile' && (
          <HStack spacing={0.5} wrap="nowrap">
            <IoBookmarkOutline color={iconColor} size={iconSize} />
            <Text fontSize={fontSize}>
              <CommaFormattedNumber>{likeCount}</CommaFormattedNumber>
            </Text>
          </HStack>
        )}

        <HStack spacing={0.5} wrap="nowrap">
          <MdHistory color={iconColor} size={iconSize} />
          <Text as="span" fontSize={fontSize}>
            <time dateTime={updateDateFormatted.replaceAll('/', '-')}>
              {updateDateFormatted}
            </time>
          </Text>
        </HStack>
      </Flex>
    </Flex>
  );
}

export default ArticleMetrics;
