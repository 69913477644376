import React from 'react';
import NextLink from 'next/link';
import NextImage from 'next/image';
import { useRouter } from 'next/router';
import {
  Box,
  Button,
  Center,
  chakra,
  HStack,
  Link,
  Spacer,
  Text,
  useMediaQuery,
  VStack,
} from '@chakra-ui/react';
import LazyLoad from 'react-lazyload';
import {
  ArticleStatus,
  Author,
  createArticleEditRelativeUrl,
  createArticleImageRelativeUrl,
  createArticleRelativeUrl,
} from '../common/libs/article';
import { createProfileImageRelativeUrl } from '../common/libs/user';
import {
  sendAnalyticsEvent,
  AnalyticsEventProps,
} from '../common/libs/googleAnalytics';
import { OFFICIAL_USER_ID } from '../common/constants';
import { DiscloseScope } from '../common/libs/discloseScope';
import { HiCheckBadge, FaLock } from './Icons';
import ArticleMetrics from './ArticleMetrics';

export type ArticleHeader = {
  articleId: string;
  name: string | null;
  status: ArticleStatus | null;
  topImageM: string | null;
  sessionCount: number;
  likeCount: number; // スライド保存とページ保存の合算値
  commentCount: number;
  createDate: string; // ISO8601
  updateDate: string; // ISO8601
  author: Author;
  discloseScope?: DiscloseScope | null;
};

export type Props = ArticleHeader & {
  layoutType?: 'horizontal' | 'vertical';
  editable?: boolean;
  badgeType?: BadgeType;
  gaEventLabel?: string;
  additionalGaEvent?: AnalyticsEventProps;
  className?: string;
};

type BadgeType = 'new' | 'updated';

function ArticleNameWithIcon({
  name,
  discloseScope = undefined,
}: {
  name: string | null;
  discloseScope?: DiscloseScope | null;
}) {
  const length = '0.875rem'; // same as fontSize of Text
  const lockStyleProps = {
    minWidth: length,
    minHeight: length,
  };

  return (
    <Box h="2.5em" display="inline-flex" gap={1}>
      {discloseScope && discloseScope === 'PARTIAL' && (
        <FaLock color="#D6BF51" style={lockStyleProps} />
      )}
      <Text
        h="2.5em"
        fontSize="sm" // '0.875rem' (chakra-ui default)
        fontWeight="bold"
        noOfLines={2}
        lineHeight="1.25"
        maxW="100%"
        wordBreak="break-all"
      >
        {name}
      </Text>
    </Box>
  );
}

/**
 * スライド画像
 *
 * 変換完了のステータスではない場合、ステータスに応じた画像を表示する。
 */
export function ArticleSummaryImage({
  articleId,
  status,
  topImageM,
  updateDate,
  alt,
}: {
  articleId: string;
  status: ArticleStatus | null;
  topImageM: string | null;
  updateDate: string;
  alt: string | null;
}) {
  let imageUrl: string | null;
  const updateDateEpochMilliseconds = new Date(updateDate).getTime();

  switch (status) {
    case 'DONE':
      imageUrl = createArticleImageRelativeUrl(articleId, topImageM, {
        version: updateDateEpochMilliseconds,
      });
      break;
    case 'CONVERTING':
      imageUrl = '/images/placeholder_article_converting.png';
      break;
    case 'DELETED':
      imageUrl = '/images/placeholder_article_deleted.png';
      break;
    case 'ERROR':
    default:
      imageUrl = '/images/placeholder_article_error.png';
      break;
  }
  return (
    <NextImage
      src={imageUrl || ''}
      alt={alt || ''}
      layout="fill"
      objectFit="contain"
    />
  );
}

/**
 * 投稿者のプロフィール画像
 *
 * プロフィール画像未設定または画像取得失敗時にはデフォルト画像を表示する。
 */
function AuthorProfileImage({ author }: { author: ArticleHeader['author'] }) {
  const imageUrl = !!author.profilePhotoS3Key
    ? createProfileImageRelativeUrl(author.userId, {
        version: author.updateDateEpochMilliseconds,
      })
    : '/images/avatar_default_gray.png';
  const fullName = `${author.lastName || ''}${author.firstName || ''}`;
  return (
    <Box pos="relative" w={8} h={8} borderRadius="full" overflow="hidden">
      <NextImage
        src={imageUrl}
        alt={fullName}
        layout="fill"
        objectFit="cover"
      />
    </Box>
  );
}

function AuthorName({
  lastName,
  firstName,
  isOfficial,
}: {
  lastName: string | null;
  firstName: string | null;
  isOfficial?: boolean;
}) {
  const fullName = `${lastName || ''}${firstName || ''}`;
  return (
    <HStack align={'flex-start'} maxW={'85%'}>
      <Text fontSize="sm" noOfLines={1} lineHeight={1.3}>
        {fullName}
      </Text>
      {isOfficial && (
        <Box ml={1}>
          <HiCheckBadge size={18} color="#008e14" />
        </Box>
      )}
    </HStack>
  );
}

/**
 * バッジ表示
 */
const ArticleBadge = chakra(
  ({ badgeType, className }: { badgeType: BadgeType; className?: string }) => {
    let text;
    switch (badgeType) {
      case 'new':
        text = 'NEW';
        break;
      case 'updated':
        text = '更新';
        break;
    }

    return (
      <Box
        className={className}
        display="inline-block"
        color="white"
        fontSize="x-small"
        fontWeight="bold"
        bg="#e26044"
        borderRadius="full"
        px={3}
        py={1}
      >
        {text}
      </Box>
    );
  },
);

/**
 * 編集リンク
 */
function ArticleEditLink({ articleId }: { articleId: string }) {
  const articleEditUrl = createArticleEditRelativeUrl(articleId);
  return (
    <NextLink href={articleEditUrl} passHref>
      <Button size="xs" variant="outline" p={2}>
        編集
      </Button>
    </NextLink>
  );
}

/**
 * ArticleSummaryコンポーネント（縦方向表示）
 *
 * 上側にスライド画像、下側にスライド情報が表示される。
 */
function ArticleSummaryVertical({
  articleId,
  name,
  status,
  topImageM,
  sessionCount,
  likeCount,
  commentCount,
  editable = false,
  updateDate,
  author,
  badgeType,
  discloseScope = undefined,
}: Props) {
  // TODO 今後isOfficialフラグをDBに持つ
  const isOfficial = author.userId === OFFICIAL_USER_ID ? true : false;
  const router = useRouter();
  const [isLargerThanLg] = useMediaQuery('(min-width: 62em)');
  const isVisibleSidebar = router.pathname === '/' && isLargerThanLg;

  return (
    <Box pos="relative">
      <Center
        pos="relative"
        h={200}
        bg="blackAlpha.300"
        borderBottomColor="gray.50"
        borderBottomWidth={1}
        overflow="hidden"
      >
        {/* <LazyLoad
          height={200}
          once
          scrollContainer={
            isVisibleSidebar ? '#mainContentContainer' : undefined
          }
        > */}
        <ArticleSummaryImage
          articleId={articleId}
          status={status}
          topImageM={topImageM}
          updateDate={updateDate}
          alt={name}
        />
        {/* </LazyLoad> */}
      </Center>
      <VStack
        align="stretch"
        spacing={1}
        overflowX="visible"
        px={3}
        py={2}
        h={124}
      >
        <ArticleNameWithIcon name={name} discloseScope={discloseScope} />
        <Spacer />
        {/* <LazyLoad height="3em" once> */}
        <HStack spacing={2} py={1} h="3em">
          <AuthorProfileImage author={author} />
          <AuthorName
            lastName={author.lastName}
            firstName={author.firstName}
            isOfficial={isOfficial}
          />
        </HStack>
        {/* </LazyLoad> */}
        <Box h="1.8em">
          <ArticleMetrics
            likeCount={likeCount}
            sessionCount={sessionCount}
            updateDateIsoString={updateDate}
            viewType="list"
          />
        </Box>
      </VStack>
      {editable && (
        <Box pos="absolute" right={2} bottom={2}>
          <ArticleEditLink articleId={articleId} />
        </Box>
      )}
      {!!badgeType && (
        <ArticleBadge badgeType={badgeType} pos="absolute" top={2} right={2} />
      )}
    </Box>
  );
}

/**
 * ArticleSummaryコンポーネント（横方向表示）
 *
 * 左側にスライド画像、右側にスライド情報が表示される。
 */
function ArticleSummaryHorizontal({
  articleId,
  name,
  status,
  topImageM,
  sessionCount,
  likeCount,
  commentCount,
  editable = false,
  updateDate,
  author,
  badgeType,
  discloseScope = undefined,
}: Props) {
  const router = useRouter();
  const [isLargerThanLg] = useMediaQuery('(min-width: 62em)');
  const isVisibleSidebar = router.pathname === '/' && isLargerThanLg;

  return (
    <HStack align="flex-start" h={110} p={2} spacing={2} pos="relative">
      <Center
        flex="0 0 120px"
        h={90}
        bg="blackAlpha.300"
        borderWidth={1}
        borderColor="gray.50"
        borderRadius="md"
        overflow="hidden"
        pos="relative"
        order={{
          base: 2,
          md: 1,
        }}
      >
        {/* <LazyLoad
          height={90}
          once
          scrollContainer={
            isVisibleSidebar ? '#mainContentContainer' : undefined
          }
        > */}
        <ArticleSummaryImage
          articleId={articleId}
          status={status}
          topImageM={topImageM}
          updateDate={updateDate}
          alt={name}
        />
        {/* </LazyLoad> */}
        {!!badgeType && (
          <ArticleBadge
            badgeType={badgeType}
            pos="absolute"
            top={1}
            right={1}
          />
        )}
      </Center>
      <VStack
        flex="1"
        minW={0}
        h="full"
        align="stretch"
        spacing={0}
        overflowX="visible"
        py={0.5}
        order={{
          base: 1,
          md: 2,
        }}
      >
        <Box h="2.6em">
          <ArticleNameWithIcon name={name} discloseScope={discloseScope} />
        </Box>
        <Spacer />
        <HStack align="center" spacing={0}>
          <ArticleMetrics
            author={author}
            likeCount={likeCount}
            sessionCount={sessionCount}
            updateDateIsoString={updateDate}
            viewType="list-mobile"
          />
          <Spacer />
          {editable && <ArticleEditLink articleId={articleId} />}
        </HStack>
      </VStack>
    </HStack>
  );
}

function ArticleSummary(props: Props) {
  const { layoutType, className } = props;

  return (
    <Box
      boxShadow="0 0 8px rgba(0,0,0,0.08)"
      borderRadius="lg"
      overflow="hidden"
      className={className}
    >
      {layoutType === 'vertical' ? (
        <ArticleSummaryVertical {...props} />
      ) : (
        <ArticleSummaryHorizontal {...props} />
      )}
    </Box>
  );
}

/**
 * リンク化されたArticleSummaryコンポーネント
 *
 * GAイベント情報が指定されていた場合、リンク押下時にGAイベントを送信する。
 */
function LinkifiedArticleSummary(props: Props) {
  const { articleId, gaEventLabel, additionalGaEvent } = props;
  const articleUrl = createArticleRelativeUrl(articleId);

  return (
    <NextLink href={articleUrl} passHref>
      <Link
        display="inline-block"
        color="inherit"
        _hover={{
          textDecoration: 'none',
        }}
        onClick={() => {
          gaEventLabel && sendAnalyticsEvent('slide', 'click', gaEventLabel);
          additionalGaEvent &&
            sendAnalyticsEvent(
              additionalGaEvent.category,
              additionalGaEvent.action,
              additionalGaEvent.label,
            );
        }}
      >
        <ArticleSummary {...props} />
      </Link>
    </NextLink>
  );
}

/**
 * メモ化されたArticleSummaryコンポーネント
 *
 * 不必要な再renderを防いでパフォーマンス向上を見込む。
 * 以下のプロパティのいずれかが変化した場合のみ再renderが必要だと判断する。
 * - articleId
 * - layoutType
 */
const MemoizedArticleSummary = React.memo(
  LinkifiedArticleSummary,
  (prevProps, nextProps) => {
    return (
      prevProps.articleId === nextProps.articleId &&
      prevProps.layoutType === nextProps.layoutType
    );
  },
);

export default chakra(MemoizedArticleSummary);
