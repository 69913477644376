import { getPublicRuntimeConfig } from '../config';

function getSlideWebBaseUrl() {
  const { slideWebBaseUrl } = getPublicRuntimeConfig();
  return slideWebBaseUrl;
}

export function createProfileImageRelativeUrl(
  userId: number,
  options?: { version?: number },
) {
  let url = `/api/profile/${userId}/image`;

  if (options?.version) {
    const p = new URLSearchParams();
    p.set('v', String(options.version));
    url = `${url}?${p.toString()}`;
  }

  return url;
}

export function createProfileImageUrl(
  userId: number,
  options?: { version?: number },
) {
  const relativeUrl = createProfileImageRelativeUrl(userId, options);
  return `${getSlideWebBaseUrl()}${relativeUrl}`;
}

export function createProfileRelativeUrl(
  userId: number,
  options?: {
    defaultIsOpenBadgeModal?: boolean;
    asSelf?: boolean;
  },
) {
  let url = options?.asSelf ? `/profile/self` : `/profile/${userId}`;

  if (options?.defaultIsOpenBadgeModal) {
    const p = new URLSearchParams();
    p.set('defaultIsOpenBadgeModal', String(options.defaultIsOpenBadgeModal));
    url = `${url}?${p.toString()}`;
  }

  return url;
}

export function createProfileUrl(
  userId: number,
  options?: {
    defaultIsOpenBadgeModal?: boolean;
    asSelf?: boolean;
  },
) {
  const relativeUrl = createProfileRelativeUrl(userId, options);
  return `${getSlideWebBaseUrl()}${relativeUrl}`;
}
